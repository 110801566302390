@import "colours";

.stat-item-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.stat-item {
  position: relative;
  height: 160px;
  min-width: 250px;
  padding: 15px 15px;

}
