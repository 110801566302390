@import "colours";

.mc-form-heading {
  background-color: #ebeef2;
  color:#7b8185;
  padding: 1rem;
  //margin: 0 !important;
  margin-bottom: 1rem;
}

.mc-form-row {
  //margin: 15px !important;
}

.mc-form-heading h5 {
  font-size: 1rem;
  font-weight: 500;
  color: #1a2a35;
}

.mc-form-row-optional,
.mc-form-row {
  padding: 15px 25px 15px 30px;
  background-color: #ebeef2;
}

.navigation {
  margin-left: 1rem;
}

.row-modifier {
  .mc-form-row-optional,
  .mc-form-row {
  background-color: #fff;
}
}

@media only screen and (max-width: 850px) {
  .mc-form-row {
    //margin: 30px !important;
  }
}