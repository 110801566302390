@import "colours";
@import "typography";
@import "variables";

.icon-tooltip-wrapper-sm {
  width: 16px;
  height: 16px;
}
.icon-tooltip-wrapper-md {
  width: 24px;
  height: 24px;
}
.icon-tooltip-wrapper-lg {
  width: 32px;
  height: 32px;
}
.icon-tooltip-wrapper-xl {
  width: 40px;
  height: 40px;
}
.icon-tooltip-wrapper-xxl {
  width: 48px;
  height: 48px;
}

.icon-tooltip-wrapper-sm,
.icon-tooltip-wrapper-md,
.icon-tooltip-wrapper-lg,
.icon-tooltip-wrapper-xl,
.icon-tooltip-wrapper-xxl {
  margin: 0;
  padding: 0;
}


.icon-dark {
  color: $mc-blue-01;

  &:hover {
    transition: color ease-in-out $animation-duration;
    color: $mc-dark-blue-hover;
    cursor: pointer;
  }
}

.icon-light {
  color: $mc-white-01;

  &:hover {
    transition: color ease-in-out $animation-duration;
    color: $mc-white-hover;
    cursor: pointer;
  }
}
